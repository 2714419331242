/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogSnippet from '../components/blogSnippet';
import { IndexHeader } from "../components/header";

const cssPosts = css`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }
  console.log(posts);

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="The Castellan Blog" />
      <IndexHeader featuredPost={posts[0]} />
      <Bio />
      <div >
      <div css={cssPosts} className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {posts.map(post => <BlogSnippet key={post.fields.slug} post={post} />)}
      </div>
      </div>
      </Layout>
  )
}
      
export default BlogIndex

/*

          featuredThumbnail: imageSharp( id: { eq: featuredimage } ) {
              sizes(maxWidth: 500 ) {
                ...GatsbyImageSharpSizes
              }
          }

          */

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMMM, YYYY")
          title
          featuredimage {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
      }
    }
  }
`
